import React, { Component } from "react";
import addToMailchimp from "gatsby-plugin-mailchimp";

export default class PortfolioDeckDownloadForm extends Component {
  state = {
    email: "",
    message: "",
  };
  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const result = await addToMailchimp(this.state.email);
    this.setState({ message: result.msg });
  };

  render() {
    return (
      <>
        {this.state.message && (
          <div className="message bg-blue-100 p-5 w-full border-l-4 border-blue-500 mt-12">
            <div className="flex space-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                className="flex-none fill-current text-blue-500 h-4 w-4"
              >
                <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-.001 5.75c.69 0 1.251.56 1.251 1.25s-.561 1.25-1.251 1.25-1.249-.56-1.249-1.25.559-1.25 1.249-1.25zm2.001 12.25h-4v-1c.484-.179 1-.201 1-.735v-4.467c0-.534-.516-.618-1-.797v-1h3v6.265c0 .535.517.558 1 .735v.999z" />
              </svg>
              <div
                className="flex-1 leading-tight text-sm text-blue-700"
                dangerouslySetInnerHTML={{ __html: this.state.message }}
              />
            </div>
          </div>
        )}
        
        <div className="my-12">
        <h3 className="text-gray-100">download my portfolio deck</h3>
        <form onSubmit={this.handleSubmit} className="flex justify-center">
            <label>
              <span className="sr-only">Email address</span>
              <input
                name="email"
                type="email"
                className="w-full lg:w-60 xl:w-72 2xl:w-96 rounded-l-lg p-4 border-t mr-0 border-b border-l text-white border-white bg-gray-800 focus:border-white focus:ring-0"
                placeholder="john@example.com"
                value={this.state.email}
                onChange={this.handleInputChange}
              />
            </label>
          <button
            className="px-8 rounded-r-lg bg-white text-gray-800 font-bold p-4 border-white-500 border-t border-b border-r"
            type="submit"
          >
            download
          </button>
        </form>
        </div>
      </>
    );
  }
}
