import React, { useState } from "react";
import axios from "axios";

const ContactForm = () => {
  const [status, setStatus] = useState({
    submitted: false,
    submitting: false,
    info: { error: false, msg: null },
  });
  const [inputs, setInputs] = useState({
    name: "",
    subject: "",
    email: "",
    message: "",
  });
  const handleServerResponse = (ok, msg) => {
    if (ok) {
      setStatus({
        submitted: true,
        submitting: false,
        info: { error: false, msg: msg },
      });
      setInputs({
        name: "",
        subject: "",
        email: "",
        message: "",
      });
    } else {
      setStatus({
        info: { error: true, msg: msg },
      });
    }
  };
  const handleOnChange = (e) => {
    e.persist();
    setInputs((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
    setStatus({
      submitted: false,
      submitting: false,
      info: { error: false, msg: null },
    });
  };
  const handleOnSubmit = (e) => {
    e.preventDefault();
    setStatus((prevStatus) => ({ ...prevStatus, submitting: true }));
    axios({
      method: "POST",
      url: "https://formspree.io/f/xoqyelaq",
      data: inputs,
    })
      .then((response) => {
        handleServerResponse(true, "Thank you! Your message has been sent.");
      })
      .catch((error) => {
        handleServerResponse(false, error.response.data.error);
      });
  };
  return (
    <>
      <div className="w-full">
        <div className="grid grid-cols-1 gap-6">
          <form onSubmit={handleOnSubmit}>
            <label className="block">
              <span className="text-gray-700">Name</span>
              <input
                id="name"
                name="name"
                type="text"
                className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
                placeholder="John Doe"
                onChange={handleOnChange}
                required
                value={inputs.name}
              />
            </label>
            <label className="block">
              <span className="text-gray-700">Email Address</span>
              <input
                id="email"
                name="_replyto"
                type="email"
                className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
                placeholder="john@example.com"
                onChange={handleOnChange}
                required
                value={inputs.email}
              />
            </label>
            <label className="block">
              <span className="text-gray-700">Subject</span>
              <select
                id="subject"
                name="_subject"
                className="block w-full mt-1 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
                onBlur={handleOnChange}
                required
              >
                <option value="General Inquiry">General Inquiry</option>
                <option value="Request for Quotation">
                  Request for Quotation
                </option>
                <option value="Report a Problem">Report a Problem</option>
              </select>
            </label>
            <label className="block">
              <span className="text-gray-700">Additional Details</span>
              <textarea
                id="message"
                name="message"
                className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
                rows="3"
                onChange={handleOnChange}
                required
                value={inputs.message}
              ></textarea>
            </label>
            <div className="block">
              <div className="mt-2">
                <div className="inline-flex items-center">
                  <p>
                    By using this form you agree with the storage and handling
                    of your data by yohan.dev
                  </p>
                </div>
              </div>
            </div>
            <div className="block">
              <div className="my-5">
                <button
                  className="focus:outline-none font-bold text-gray-600 py-2.5 px-5 rounded-md border border-gray-600 hover:bg-gray-50"
                  type="submit"
                  disabled={status.submitting}
                >
                  {!status.submitting
                    ? !status.submitted
                      ? "send"
                      : "sent!"
                    : "sending..."}
                </button>
              </div>
            </div>
          </form>
          {status.info.error && (
            <div className="text-red-600 font-bold">
              Error: {status.info.msg}
            </div>
          )}
          {!status.info.error && status.info.msg && (
            <div className="text-green-600 font-bold">{status.info.msg}</div>
          )}
        </div>
      </div>
    </>
  );
};

export default ContactForm;
