import React, { Component } from "react";
import Slider from "react-slick";
import { StaticImage } from "gatsby-plugin-image";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class Portfolio extends Component {
  render() {
    var settings = {
      dots: false,
      infinite: true,
      speed: 2000,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      arrows: false,
      autoplay: true,
      responsive: [
        {
          breakpoint: 1536,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className="xl:mt-8">
        <Slider {...settings}>
          <a
            href="http://delunecorp.com"
            rel="nofollow noopener noreferrer"
            target="_blank"
            className="focus:outline"
          >
            <div className="portfolio-card rounded-xl bg-blur-10 p-6 mx-5 my-5 shadow-tiny">
              <h3 className="text-sm xl:text-base leading-tight mb-2">
                De Lune Corp Website
              </h3>
              <button type="button" className="rounded-full text-sm px-4 bg-gray-300 p-2 leading-none flex items-center">
                WordPress
              </button>
              <StaticImage
                src="../images/work/delune.png"
                width={500}
                quality={80}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="A preview of De Lune Corp's website."
                className="object-cover shadow-blue"
              />
            </div>
          </a>

          <a
            href="http://1833buyeggs.com"
            rel="nofollow noopener noreferrer"
            target="_blank"
            className="focus:outline"
          >
            <div className="portfolio-card rounded-xl bg-blur-10 p-6 mx-5 my-5 shadow-tiny">
              <h3 className="text-sm xl:text-base leading-tight mb-2">
                1-833 Buy Eggs Online Shop
              </h3>
              <button type="button" className="rounded-full text-sm px-4 bg-gray-300 p-2 leading-none flex items-center">
                Laravel
              </button>

              <StaticImage
                src="../images/work/1833-buy-eggs.png"
                width={500}
                quality={80}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="A preview of 1-833 Buy Eggs's website."
                className="object-cover shadow-blue"
              />
            </div>
          </a>

          <a
            href="http://mrpods.com"
            rel="nofollow noopener noreferrer"
            target="_blank"
            className="focus:outline"
          >
            <div className="portfolio-card rounded-xl bg-blur-10 p-6 mx-5 my-5 shadow-tiny">
              <h3 className="text-sm xl:text-base leading-tight mb-2">
                Mr. Pod's Website
              </h3>
              <button type="button" className="rounded-full text-sm px-4 bg-gray-300 p-2 leading-none flex items-center">
                WordPress
              </button>
              <StaticImage
                src="../images/work/mrpods.png"
                width={500}
                quality={80}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="A preview of Mr. Pod's website."
                className="object-cover shadow-blue"
              />
            </div>
          </a>

          <a
            href="http://wedotax.com.au"
            rel="nofollow noopener noreferrer"
            target="_blank"
            className="focus:outline"
          >
            <div className="portfolio-card rounded-xl bg-blur-10 p-6 mx-5 my-5 shadow-tiny">
              <h3 className="text-sm xl:text-base leading-tight mb-2">
                WE DO TAX Website
              </h3>
              <button type="button" className="rounded-full text-sm px-4 bg-gray-300 p-2 leading-none flex items-center">
                WordPress
              </button>
              <StaticImage
                src="../images/work/wedotax.png"
                width={500}
                quality={80}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="A preview of We Do Tax's website."
                className="object-cover shadow-blue"
              />
            </div>
          </a>

          <a
            href="http://companysecretary.lk"
            rel="nofollow noopener noreferrer"
            target="_blank"
            className="focus:outline"
          >
            <div className="portfolio-card rounded-xl bg-blur-10 p-6 mx-5 my-5 shadow-tiny">
              <h3 className="text-sm xl:text-base leading-tight mb-2">
                CompanySecretary.lk Website
              </h3>
              <button type="button" className="rounded-full text-sm px-4 bg-gray-300 p-2 leading-none flex items-center">
                WordPress
              </button>
              <StaticImage
                src="../images/work/companysecretary-lk.png"
                width={500}
                quality={80}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="A preview of CompanySecretary.lk's website."
                className="object-cover shadow-blue"
              />
            </div>
          </a>

          <a
            href="http://srilankaunites.org"
            rel="nofollow noopener noreferrer"
            target="_blank"
            className="focus:outline"
          >
            <div className="portfolio-card rounded-xl bg-blur-10 p-6 mx-5 my-5 shadow-tiny">
              <h3 className="text-sm xl:text-base leading-tight mb-2">
                Sri Lanka Unites Website
              </h3>
              <button type="button" className="rounded-full text-sm px-4 bg-gray-300 p-2 leading-none flex items-center">
                WordPress
              </button>
              <StaticImage
                src="../images/work/slu.png"
                width={500}
                quality={80}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="A preview of Sri Lanka Unites's website."
                className="object-cover shadow-blue"
              />
            </div>
          </a>

          <a
            href="http://theplanter.life"
            rel="nofollow noopener noreferrer"
            target="_blank"
            className="focus:outline"
          >
            <div className="portfolio-card rounded-xl bg-blur-10 p-6 mx-5 my-5 shadow-tiny">
              <h3 className="text-sm xl:text-base leading-tight mb-2">
                Planter
              </h3>
              <button type="button" className="rounded-full text-sm px-4 bg-gray-300 p-2 leading-none flex items-center">
                Gatsby
              </button>
              <StaticImage
                src="../images/work/planter.png"
                width={500}
                quality={80}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="A preview of Planter's website."
                className="object-cover shadow-blue"
              />
            </div>
          </a>
        </Slider>
      </div>
    );
  }
}
